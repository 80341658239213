import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import "tachyons/css/tachyons.min.css";
import "../../css/common.css";

import { COOKIES_KEY } from "../../constants/constants";

import Header from "../../components/Header";
import TitlePage from "../../components/ui/TitlePage";
import SectionTextPage from "../../components/ui/SectionTextPage";
import Footer from "../../components/Footer";
import WrapperLink from "../../components/ui/WrapperLink";
import CookiesSettings from "../../components/CookiesSettings";
import ListText from "../../components/ui/ListText";

const CookiesPolicy = () => {
  const cookiesSettings = { statistical: false };

  const { t } = useTranslation();

  const [cookiesAccepted, setCookiesAccepted] = useState(false); // Info cookie
  const [cookiesSettingsEdit, setCookiesSettingsEdit] = useState(cookiesSettings); // Settings cookie in the process of being modified

  const [showSettings, setShowSettings] = useState(false); // Show or Hide the settings modal

  useEffect(() => {
    const cookiesAcceptance = localStorage.getItem(COOKIES_KEY) || false;
    if (false !== cookiesAcceptance) {
      setCookiesAccepted(JSON.parse(cookiesAcceptance));
      setCookiesSettingsEdit(JSON.parse(cookiesAcceptance));
    }
  }, []);

  const openSettingsPage = () => {
    setShowSettings(true);
  };

  const closeSettingsPage = () => {
    setShowSettings(false);
  };

  const switchStatisticalHandler = () => {
    setCookiesSettingsEdit(cookiesSettingBefore => {
      var cookiesSettingEdit = { ...cookiesSettingBefore };
      cookiesSettingEdit.statistical = !cookiesSettingEdit.statistical;
      return cookiesSettingEdit;
    });
  };

  const saveApplyHandler = () => {
    localStorage.setItem(COOKIES_KEY, JSON.stringify(cookiesSettingsEdit));
    setCookiesAccepted(cookiesSettingsEdit);
    setShowSettings(false);
    window.location.reload();
  };

  return (
    <>
      <Header />
      {showSettings && (
        <CookiesSettings
          closeSettingsPage={closeSettingsPage}
          saveApplyHandler={saveApplyHandler}
          switchStatisticalHandler={switchStatisticalHandler}
          cookiesSettingsEdit={cookiesSettingsEdit}
        />
      )}
      <SectionTextPage>
        <TitlePage category="cookies" title={t("privacy-cookies.title")} />
        <div>
          <button className="bn bg-black hover-bg-black-70 white ph3 pv2 abel b pointer" onClick={openSettingsPage}>
            {t("privacy-cookies.btn-manage")}
          </button>
        </div>
        <p className="section-subtitle">{t("privacy-cookies.subtitle")}</p>
        <p>{t("privacy-cookies.s1-p1")}</p>
        <p className="section-subtitle">{t("privacy-cookies.s2-1")}</p>
        <p>{t("privacy-cookies.s2-p1")}</p>
        <p className="section-subtitle">{t("privacy-cookies.s3-1")}</p>
        <p>{t("privacy-cookies.s3-p1")}</p>

        <ListText text={t("privacy-cookies.s3-p2")} />
        <ListText text={t("privacy-cookies.s3-p3")} />
        <ul className="pl5 pl6-l">
          <li>{t("privacy-cookies.s3-p3-1")}</li>
          <li>
            {t("privacy-cookies.s3-p3-2")}
            <br />
            <WrapperLink
              href="https://policies.google.com/technologies/partner-sites"
              target="_blank"
              className="link blue"
            >
              https://policies.google.com/technologies/partner-sites
            </WrapperLink>
          </li>
          <li>
            {t("privacy-cookies.s3-p3-3")}{" "}
            <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" className="link blue" rel="noreferrer">
              https://tools.google.com/dlpage/gaoptout
            </a>
          </li>
        </ul>

        <p className="section-subtitle">{t("privacy-cookies.s4-1")}</p>
        <p dangerouslySetInnerHTML={{ __html: t("privacy-cookies.s4-p1") }}></p>
        <p className="section-subtitle">{t("privacy-cookies.s5-1")}</p>
        <p>{t("privacy-cookies.s5-p1")}</p>
        <p className="section-subtitle">{t("privacy-cookies.s6-1")}</p>
        <p dangerouslySetInnerHTML={{ __html: t("privacy-cookies.s6-p1") }}></p>

        <p className="section-subtitle">
          {t("privacy-cookies.nottrack")} <span className="black-30 normal">{"(?)"}</span>
        </p>
        <p>{t("privacy-cookies.nottrack-text")}</p>
        <div className="flex flex-column b mt3">
          <div className="mt2">&gt; Chrome</div>
          <div className="mt2">&gt; Firefox</div>
          <div className="mt2">&gt; Internet Explorer</div>
          <div className="mt2">&gt; Opera</div>
          <div className="mt2">&gt; Safari</div>
        </div>
      </SectionTextPage>
      <Footer />
    </>
  );
};

export default CookiesPolicy;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
